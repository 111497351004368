// ==========================================================================
// TABLE OF CONTENTS:
// ==========================================================================
//
// # General
// # Header
// 		# Title + description
// 		# Navbar
// # Wrapper
// # Content
// # Sidebar and Widgets
// # Components
// 		# Fancy skip link
// 		# Pagination
// 		# Comments
// # Footer
//
 
// ==========================================================================
// General
// ==========================================================================
body {
    overflow-x:hidden;
    font-family: 'Dosis', sans-serif;
    @media (min-width: $screen-tablet) {
        font-size: 14px;
    }




}
.no-padding{
    padding:0;
}
.no-margin{
    margin:0;
}
::-moz-selection {
    background: $brand-primary;
    color:  $brand-primary;
}

::selection {
    background: $brand-primary;
    color: $body-bg;
}

a,
button,
input[type="button"] {
    @include transition-property(all, ease-in-out);
    @include transition-duration(.2s);
}

// ==========================================================================
// Header
// ==========================================================================
.home {
    #header {
        margin-bottom: 0;
        // background-color:red;
    }
}

#header {
    margin-bottom: 4em;
    padding: 0 0 1em 0;
    position: relative;
 
    // background-color:red;
    // Title, description and image
    //
    .site-logo {
        display: block;
        width: 123px;
        // border: 2px solid red;
        margin-left: -2em;
        z-index: 2 !important;
        @media (min-width: $screen-tablet) {
            // margin-top: 2em;
            position: absolute;
            z-index: 9999 !important;
            width: 60%;

        }

    }
}

.langs-m {
    background: #f1f1f1;
    padding: .5em;
    font-size: 80%;
}

//
// Navbar
//
 

#barra-topo {
    content: "";
    background: url(../images/barra-topo.jpg) no-repeat center center;
    bottom: 0;
    position: absolute;
    height: 46px;
    width: 100%;
    left: 0;
    right: 0;
    top: 2em;
    z-index: -1;
}
 
 
 
#main-navigation {

    .navbar-collapse {
        overflow-x: hidden;
        overflow-y: hidden;
    }
 
    .nav>li>a {
     
      padding: 10px 8px;
    }

    //  @media (max-width: 991px) {
    //     .navbar-collapse.collapse {
    //       width: 111%;
    //       height: auto !important;
    //       padding-bottom: 0;
    //       overflow: visible !important;
    //       margin: .1em -1em 0em -1.6em;
    //     }
    // }
    .navbar-nav {
    //     ul{
             
    //         @media (min-width: $screen-tablet) {
    //             margin:.1em 0 0 -1em;
    //         }        
    //         @media (min-width: $screen-desktop) {
    //             margin:.0em;
    //         }        
    //         @media (min-width: $screen-lg) {
    //              margin:.0em;
    //         }

    //     }
 
        li:nth-child(1) {  
            border-bottom: 3px solid $brand-secondary;
        }
        li:nth-child(2) {  
            border-bottom: 3px solid $brand-tertiary;
        }
        li:nth-child(3) {  
            border-bottom: 3px solid $brand-quart;
        }
        li:nth-child(4) {  
            border-bottom: 3px solid $brand-quint;
            @media (max-width: 767px) {
                display: inline-block;
                 width: 100%;
            }
 



        }
        li:nth-child(5) {  
            border-bottom: 3px solid $brand-sext;
        }

        // margin: 0px -15px;
        margin-top: 0em;
        margin-left: 0em;
        // margin: 1em 0 0 0;
        @media (max-width: 479px) {
            margin-bottom: 0em;
        }
  
        @media (min-width: $screen-phone) {
             margin-bottom: 0em;
        }

        @media (min-width: $screen-tablet) {
            // .navbar-nav {
            //     margin: 2.5em 0 0 0;
            // }
       
        }
        @media (max-width: 1199px) {
            width: 111%;
            margin:.1em 0 0 -1em;
            // margin-left: .2em;
        }
        @media (max-width: 767px) {
            // width: 100%;
            margin-left: -0.9em;
        }
 
        .lan{
            margin-top: 1.33em;

            // @media (min-width: $screen-phone) {
            //     margin-top: 0em;
            // }
 
            @media (max-width: 767px) {
                margin-top: 0em;
            }
        }
 
        // .barra{
        //     width: 3px;
        //     height: 20px;
        //     background-color:#acc7e0;
        //     display: inline-block;
        //     margin-bottom: -3em;
        //     // display:inline;
        // }
 

        > li {
            // margin-right: -1em;
            // border-left: 3px solid white;

            // .esconde:nth-child(1) {
            //   display:none;
            // }
            height: 3px;
            /* color: blue; */
            border-bottom: 3px solid blue;
            /* border-top: 2px solid red; */
            // padding: 5px;
            margin: 0 0 0 4px;
            height: 2.7em;
        
            > a {
             
                position: relative;
                font-size: 16px;
                 
                font-weight: bolder;
                text-transform: uppercase;
                color: #3e3e3e;
                outline: none;
               

                @media (min-width: $screen-tablet) {
                    // font-size: inherit;
                    // padding: 14px 15px;
                    // @include border-radius(0 0 20px 20px);
                     font-size: 13px;
                    &:hover,
                    &:focus {
                        // background-color: $brand-secondary;
                        color: lighten(#3e3e3e, 15%);
                        background-color: transparent;
                    }
                }

                @media (min-width: $screen-desktop) {
                    font-size: 15px;
                    // padding: 40px 8px 8px;
                }

 
                @media (max-width: 767px) {
                    padding-bottom: 2px;
                }
 
                @media (min-width: $screen-lg) {
                    font-size: 15px;
                    // padding: 40px 20px 20px;
                }
            }
 
            &.active {
                > a {
                    background-color: transparent;
                    color: #3e3e3e;
                    // padding: 15px 2px 3px 4px;

                }
            }
        }
 
       
       // .open{
       //  .dropdown-menu {
       //      position: relative;
       //  }

       // }



        // .dropdown-menu {
        //     position: relative;
        //     float: none;
        //     width: auto;
        //     margin-top: 0;
        //     background-color: transparent;
        //     border: 0;
        //     box-shadow: none;
        // }

        li>.dropdown-menu {
            background-color: $brand-quart;
            
            @media (max-width: 479px) {
                float: left;
                width: 100%;
                position: relative;
                margin: -31px 1px 48px 0px;
            }
            @media (min-width: $screen-phone) {
                float: left;
                width: 100%;
                position: relative;
                margin: -31px 1px 48px 0px;
            }
 
            @media (min-width: $screen-tablet) {
                position: absolute;
                margin: 0px 21px 0px 0px;
            }
            @media (min-width: $screen-desktop) {
                position: absolute;
                margin: 0px 21px 0px 0px;
            }
            @media (min-width: $screen-lg) {
                position: absolute;
                margin: 0px 21px 0px 0px;
            }
   
         
            a{
                color:white;
                padding: 1px 10px;
                font-size: .9em;
                font-weight: 700;
                text-transform:uppercase;
                line-height: 2.42857;
                      
                &:hover,
                &:focus {
                     background-color: darken($brand-quart, 5%);
                }
                img{
                    padding: 0px 3px 5px 0px;
                }
            }
        }

  


 
        // // li>.dropdown-menu:nth-child(1) {
        // //     border-bottom: 3px solid white;
        // // }
        
        // .dropdown-menu>ul>li {
        //    border-bottom: 3px solid white;
 
        // }

 

        // .dropdown-menu>li>a {
        //     color: white;
 
        // }
    }
}



.my-gallery {
    .thumbnail {
        float: left;
        margin: 0 3.8% 2.992em 0;
        padding: 0;
        position: relative;
        width: 22.05%;
        margin-left: 0;
        padding: .2em;

        &:nth-child(4n+1) {
            clear: both;
        }

        &:nth-child(4n+4) {
            margin-right: 0;
        }
        section{
           outline: none  !important;
        }

        a {
            > img {
                width: 100%;
            }
        }

        .caption {
            padding: 3px 2px;
        }
    }

    figure {
        margin: 0;
    }
}

 
 

// ==========================================================================
// Menu 2
// ==========================================================================

#main-navigation-2 {
    
 
     margin-left: -3em;
    .navbar-nav {
        display:flex;
        height:100%;
     }

    .dropdown:hover .dropdown-menu {
        display: block;
        border: 0;

    }

    .dropdown-menu {
      
        @media (min-width: $screen-tablet) {
            min-width: 35em;
            min-height: 8em;
            padding-top: 1.7em;
        }
        @media (min-width: $screen-desktop) {
            min-width: 35em;
            min-height: 8em;
            padding-top: 1.7em;
        }
        @media (min-width: $screen-lg) {
            min-width: 35em;
            min-height: 8em;
            padding-top: 1.7em;
        }


       li{
          a{
 
             padding: 0px 18px;
 
           }
       }
 

    }
 
 
 

    ul{
        // .dropdown-menu {
        //     right: 0;
        // }
     .menu_direita   {
       right: 0;
       left: auto;
    }
 
        li:nth-child(4){
            height:7.6em;
            // background-color:red;
        }
  

        li{
            text-align:center;
            padding: 0px 0px 0px 0px;
            display: inline;
            width: 9%;
            img:hover{
                background: url(../assets/images/menus-icones-white/banheiro.png) no-repeat center center !important;
            }

            a{
                color:#313131;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: center;
                padding-top:0;
                padding-bottom:0;
            
            img:hover{
                background: url(../assets/images/menus-icones-white/banheiro.png) no-repeat center center !important;
            }

 
            }



            >:hover, .dropdown-menu > li > :focus {
                background-color:#ede7dd;
                // height:9em;
                // color:white !important;
                // position:absolute;

            }
        
            p{
                font-size: .9em;
                font-weight: 700;
                line-height: 17px;
                text-transform: uppercase;
                color: #585858;
                    &:hover,
                    &:focus {
                    // color:white !important;
                       // margin-top:-3em !important;
                    }   
            }
 
            ul{
    .menu_direita   {
       right: 0;
       left: auto;
    }
              
                li{ // linha das imagens
                     
                    .imagem-horizontal{
                        display:inline;

                        &:hover,
                        &:focus {
                        // color:white !important;
                           // margin-top:-3em !important;
                        }   

                    }
                  
                    margin: 0em -1em 0em 0em;
                    .thumb{
                       
                    
                        @media (min-width: $screen-tablet) {
                           width:15%;
                        }
                        @media (min-width: $screen-desktop) {
                            width:20%;
                            border: 2px solid #adadad;
                            opacity: 1.0;
                            filter: alpha(opacity=100); /* For IE8 and earlier */
                        }
                        @media (min-width: $screen-lg) {
                            width:20%;
                            border: 2px solid #adadad;
                            opacity: 1.0;
                            filter: alpha(opacity=100); /* For IE8 and earlier */

                        }

                        &:hover,
                        &:focus {
                            // background-color: $brand-secondary;
                            opacity: 0.8;
                            filter: alpha(opacity=80); /* For IE8 and earlier */
                            border: 2px solid #7a7a7a;
                           
                            


                        }
                    }
 
                }
                span{
                    padding-right: 2.4em;
                    margin-top: .5em;
                    color: white;
                }
            }

            ul {

                background-color:#ede7dd;
            
            }
          
       

        }
    }
}
// ==========================================================================
// Menu 2
// ==========================================================================
    .menu_direita   {
       right: 0;
       left: auto;
    }

// ==========================================================================
// Menu 3
// ==========================================================================

#main-navigation-3 {
    
    .dropdown:hover .dropdown-menu {
        display: block;
        border: 0;
 
  

    }

     

    .dropdown-menu {
        min-width: 35em;
        min-height: 8em;
        padding-top: 1.7em;
        // float:right;
      
    }
 


    ul{
        // .dropdown-menu {
        //     right: 0;
        // }
 
 
        li:nth-child(2){
            height:7.6em;
            background-color:red;
        }

        li{
            text-align:center;
            padding: 13px 0px 0px 0px;
            display: inline;
            a{
                color:white;
            }


            >:hover, .dropdown-menu > li > :focus {
                background-color:$brand-secondary;
                // height:9em;
                color:white !important;
                // position:absolute;

            }
        
      
 


            p{
                font-size: 1em;
                font-weight: 700;
                text-transform: uppercase;
                color: #585858;
                    &:hover,
                    &:focus {
                    color:white !important;
                       // margin-top:-3em !important;
                    }   
            }

            ul{
              
                li{ // linha das imagens
                     
                    .imagem-horizontal{
                        display:inline;

                        &:hover,
                        &:focus {
                        color:white !important;
                           // margin-top:-3em !important;
                        }   

                    }
                  
                    margin: 0em 0em 0em 1em;
                    .thumb{
                        width:20%;
                    }
 
                }
                span{
                    padding-right: 2.4em;
                    margin-top: .5em;
                    color: white;
                }
            }

            ul {

                background-color:$brand-secondary;
            
            }
          
       

        }
    }
}
// ==========================================================================
// Menu 2
// ==========================================================================











//
// Search
//
.search-wrapper {
    float: right;

    @media (min-width: $screen-tablet) {
        float: left;
    }

    > a {
        @media (min-width: $screen-tablet) {
            display: inline-block;
            float: right;
            line-height: 8;
            vertical-align: middle;
        }
    }
}
 


//
// Nav toggle
//
.toggle-wrapper {
    @media (min-width: $screen-tablet) {
        display: none;
    }
}

.navbar-toggle {
    @media (min-width: $screen-desktop) {
        display: none;
    }

    float: none;
    padding: 0;
    position: relative;

    &:hover,
    &:focus {
        .icon-bar {
            background-color: darken($brand-primary,15%);
        }
    }

    .icon-bar {
        background-color: $brand-primary;
        border-radius: 25px;
        height: 4px;
        width: 25px;
        @include transition-property(all, ease-in-out);
        @include transition-duration(.2s);

        + .icon-bar {
            margin-top: 3.5px;
        }
    }

    &.btn-close {
        z-index: 110;

        .icon-bar.bar1 {
            @include transform-origin(10% 80%);
            @include rotate(45deg);
        }

        .icon-bar.bar3 {
            @include transform-origin(10% 30%);
            @include rotate(-45deg);
        }

        .icon-bar.bar2 {
            background-color: transparent;
        }
    }
}


// ==========================================================================
// Display-category
// ==========================================================================
#display-category{

    outline:none;
    padding: 3em 0em 0em 0em;
    @media (max-width: $screen-phone) {
        padding: 0em 0em 0em 0em;
    }

 
    // .overlay {
    //   position: absolute;
    //   bottom: 100%;
    //   left: 15px;
    //   right: 0;
    //   background-color: transparent;
    //   background: url(../images/organizacao-display.jpg) no-repeat center center;
    //   overflow: hidden;
    //   // opacity:.8;
    //   width: 95%;
    //   height:0;
    //   transition: .5s ease;
    // }

    // .container:hover .overlay {
    //   bottom: 0;
    //   height: 100%;
    // }
    .overlay {
        position: absolute;
        background: url(../images/organizacao-display.jpg) no-repeat center center;
        top: 0;
        bottom: 0;
        left: 13px;
        right: 0;
        height: 100%;
        width: 94%;
        opacity: 0;
        transition: .5s ease;
        background-color: transparent;
    }

    .overlay:hover {
        opacity: 1;
    }

    .box-big{
        width: 51%;
         margin: .4em 0em 0em 0em;
    }
    .box{
        width: 22%;
        padding: .2em;
        margin-top: .1em;
     }



 
    .text {
        white-space: nowrap; 
        color: white;
        font-size: 3em;
        text-transform: uppercase;
        position: absolute;
        overflow: hidden;
        top: 50%;
        left: 50%;
        // visibility:visible;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
       

            @media (min-width: $screen-phone) {
                font-size: 2em;
            }
           

            @media (min-width: $screen-tablet) {
               font-size: 2em;


            }
            @media (min-width: $screen-desktop) {
                font-size: 2.6em;

            }
            @media (min-width: $screen-lg) {
                font-size: 3em;

            }




        &:hover{
        // visibility:hidden;
        }
    }


    .overlay-cozinha {
        position: absolute;
        background: url(../images/cozinha-display.jpg) no-repeat center center;
        top: 0;
        bottom: 0;
        left: 15px;
        right: 0;
        height: 100%;
        width: 90%;
        opacity: 0;
        transition: .5s ease;
        background-color: transparent;
    }

    .overlay-cozinha:hover {
        opacity: 1;
        top: 3px;
        bottom: 0;
        left: 3px;
        right: 0;
        height: 98%;
        width: 98%;
    }

 
    
    .overlay-mesa {
        position: absolute;
        background: url(../images/mesa-display.jpg) no-repeat center center;
        top: 0;
        bottom: 0;
        left: 15px;
        right: 0;
        height: 100%;
        width: 90%;
        opacity: 0;
        transition: .5s ease;
        background-color: transparent;
    }

    .overlay-mesa:hover {
        opacity: 1;
        top: 3px;
        bottom: 0;
        left: 3px;
        right: 0;
        height: 98%;
        width: 98%;
    }

    .overlay-microondas {
        position: absolute;
        background: url(../images/microondas-display.jpg) no-repeat center center;
        top: 0;
        bottom: 0;
        left: 15px;
        right: 0;
        height: 100%;
        width: 90%;
        opacity: 0;
        transition: .5s ease;
        background-color: transparent;
    }

    .overlay-microondas:hover {
        opacity: 1;
        top: 3px;
        bottom: 0;
        left: 3px;
        right: 0;
        height: 98%;
        width: 98%;
    }


    .overlay-limpeza {
        position: absolute;
        background: url(../images/limpeza-display.jpg) no-repeat center center;
        top: 0;
        bottom: 0;
        left: 15px;
        right: 0;
        height: 100%;
        width: 90%;
        opacity: 0;
        transition: .5s ease;
        background-color: transparent;
    }

    .overlay-limpeza:hover { 
        opacity: 1;
        top: 3px;
        bottom: 0;
        left: 3px;
        right: 0;
        height: 98%;
        width: 98%;
    }

}





// ==========================================================================
// Display-category
// ==========================================================================



// ==========================================================================
// Receitas
// ==========================================================================
#receitas{
    outline:none;
    padding: 1em 0em 1em 0em;
    a{
        color: #ffffff;
    }
 
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        font-weight: 100;
         
        color: #7b7b7b;
    }
    h2{
        color: #ac5b5b;
        font-weight: 800;
    }

    h5{
        color: #313131;
        font-weight:none;
        margin-left:1em;
    }


    .button{
        color:white;
    }

    .textos{
         margin: 0 0 0 1.3em;
         font-size: 17px;
    }
    .foto-receita{
       margin-top: -3em;
    }
    .imagem-revento{
   
      max-width: 100%;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      margin-top: 5em;
    }
    .textos_evento{ 
   

        @media (min-width: $screen-phone) {
            // font-size: 2em;
        }
       

        @media (min-width: $screen-tablet) {
           font-size: 18px;
           padding: 1.5em;
           margin-top: -1.2em;
           margin-left: -1.5em;
        }
        @media (min-width: $screen-desktop) {
            font-size: 19px;
            margin-left: -0.9em;
            margin-top: -1.9em;
        }
        @media (min-width: $screen-lg) {
            font-size: 19px;
            margin-left: -0.9em;
            margin-top: -1.9em;
        }
    }

    .evento_div{
       padding: 0em 0em 0em 2em;
    
        @media (max-width: $screen-phone) {
            padding: 0em 0em 0em 0em;
        }
       
        @media (min-width: $screen-tablet) {
           padding: 0em 0em 0em 2em;
        }
        @media (min-width: $screen-desktop) {
           padding: 0em 0em 0em 2em;
        }
        @media (min-width: $screen-lg) {
           padding: 0em 0em 0em 2em;
        }
    }
    .textos_evento,a { 
         color:#c67777;
    }
}

// ==========================================================================
// Receitas
// ==========================================================================


 
// ==========================================================================
// modulos
// ==========================================================================
#modulos{
    outline:none;
    padding: 0em 0em 1em 0em;
    a{
        color: #7b7b7b;
    }
    .box-2{
        margin-top: 4.2em;
    }
    .previa-texto{
           
          @media (min-width: 480px) {
             padding: 0;
             margin: 10px 000;
          }      
           @media (min-width: phone) {
             padding: 0;
             margin: 10px 000;
          }            
          @media (min-width: $screen-tablet) {
             padding: 0;
             margin: 10px 000;
          }  
          @media (min-width: $screen-desktop) {
             padding: 0;
             margin: -4px 0px 0px 6px;
          }  
    
 
     }
    .img-feiras{
        @media (min-width: $screen-tablet) {
            margin-top:2em;
        }  



        @media (min-width: $screen-desktop) {
            margin-top:2em;

        }   
        @media (min-width: $screen-lg) {
            margin-top:2em;

        }
    }
    .titulo-modulo{
        min-height:2.1em;
    }
    .link-receitas{
        margin: 16px -53px 0px 0px;
        z-index: 999;
        @media (min-width: $screen-phone) {
             font-size: 1.1em;

        }      
        @media (min-width: $screen-tablet) {
             font-size: 1.2em;

        }  
        @media (min-width: $screen-desktop) {
            font-size: 1.3em;

        }   
        @media (min-width: $screen-lg) {
            font-size: 1.3em;

        }
 
    }
  
    .img-feiras-big{
        @media (min-width: $screen-tablet) {
            margin-top:5.4em;

        }  
        @media (min-width: $screen-desktop) {
            margin-top:3em;

        }   
        @media (min-width: $screen-lg) {
            margin-top:3em;

        }
    }

 


 

    //  ._10b4{
    //      max-height: 402px !important;
    // }
    // ._2lqg{
    //      max-height: 408px !important;
    // }
    .modulo-evento{
        
        @media (max-width: 479px) {
            margin-top:3em;
        }
        @media (min-width: $screen-phone) {
             margin-top:3em;
        }     
        @media (min-width: $screen-tablet) {
             margin-top:0;
        }
        @media (min-width: $screen-desktop) {
             margin-top:0;
        }
        @media (min-width: $screen-lg) {
             margin-top:0;
        }      
    }
    .face{
        margin-top:.2em;
        width: 46%;
        
        @media (max-width: 479px) {
            width: 100%;
        }
        @media (min-width: $screen-phone) {
            width: 100%;
        }     
        @media (min-width: $screen-tablet) {
            width: 46%;
        }
        @media (min-width: $screen-desktop) {
            width: 46%;
        }
        @media (min-width: $screen-lg) {
            width: 46%;
        }

    }
    .form-control {
        height: 28px;
        border: 3px solid #0c9191;
        border-radius: 0;
    }
    
    .box-foto{
        margin-top:11px;
    }
 
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        font-weight: 100;
        color: #7b7b7b;
        // min-height: 2.1em;

    }
     
    .sobre{
       padding: 2.3em;
    }
    form{
          margin: 4.5em 000;
            @media (min-width:$screen-tablet) {
                  margin: 0em 0 0 -23px;

            }
  
        h4{
            color: #0c9191;
            font-weight: 800;
         
            @media (min-width: $screen-phone) {
                // .navbar-nav {
                //     margin: 2.5em 0 0 0;
                // }
            }
           

            @media (min-width: $screen-phone) {
                margin: 0px 0px 17px 0.2em;
            }
           

            @media (min-width: $screen-tablet) {
                margin: 0px 0px 17px .8em;
                font-size: 1.1em;


            }
            @media (min-width: $screen-desktop) {
                 margin: 32px 0px 17px .8em;
                 font-size: 1.2em;

            }
            @media (min-width: $screen-lg) {
                  margin: 0px 0px 17px 0.7em;
                  font-size: 1.2em;

            }
  


        }
       


        label{
            color: #0c9191;
            font-weight: 800;     
        }
        button {
            color: #0c9191;
            font-weight: 800;
            border: 3px solid #0c9191;
            padding: 0.1%;
            margin-left: 1.9em;
            width: 30px;

       
            @media (max-width: 479px) {
                // width: 15%;
                float: right;
            }

            @media (min-width: $screen-phone) {
                // width: 15%;
                float: right;
            }
           
            @media (min-width: $screen-tablet) {
                // width: 129%;
                float: left;
                margin-left: 0em;
            }
            @media (min-width: $screen-desktop) {
                // width: 117%;
                float: left;
                margin-left: 0em;
            }
            @media (min-width: $screen-lg) {
                // width: 117%;
                float: left;
                margin-left: 0;
                margin-top: 0;
            }
 
        }
     
    }
 
}


  


// ==========================================================================
// modulos
// ==========================================================================


// ==========================================================================
// Wrapper
// ==========================================================================
#wrapper {
    background-color: white;
    z-index: 999;
    position: relative;
    width: 100%;
}

// ==========================================================================
// Container
// ==========================================================================
#container {
    background-color: white;
}


 
// ==========================================================================
// Content
// ==========================================================================
#content {
    
 
   
    .bg{
        content: "";
        background: url(../images/bg-menu-2.png) no-repeat center center;
        bottom: 0;
        position: absolute;
       
        width: 100%;
        left: 0;
        right: 0;
        top: 0em;
        z-index: -1;
        @media (max-width: 479px) {
            height: 0px;
        }

        @media (min-width: $screen-phone) {
            height: 0px;
        }
        @media (min-width: $screen-tablet) {
            height: 244px;
        }
        @media (min-width: $screen-desktop) {
            height: 110px;
        }
        @media (min-width: $screen-lg) {
            height: 110px;
        }
    }

    // .nav{
        
    //     li{
    //         a{
    //             img{
                     
    //                 @media (min-width: $screen-desktop) {
    //                     max-width: 56px;
    //                 }
    //                 @media (min-width: $screen-lg) {
    //                     max-width: 75px;
    //                 }

    //             }
    //         }
    //     }
    // }
 
    .categoria-titulo-prod{

        h1{
            color:$brand-quart;
            margin: -1.1em 0em 0em 1.8em;
            span{
                font-size: .7em;
            }
            img{
                margin:0 0 8px 0px;
                @media (max-width: 479px) {
                    font-size:20px;  
                    margin:2px 0 10px -12px;
                }
             


            }

            @media (max-width: 479px) {
                font-size:25px;  
                margin:-1.7em 0em 0em 2.3em;
            }
              
 

        }
        

        h2{
            color:$brand-quart;
             @media (max-width: 479px) {
                font-size:20px;  
            }
        }

    }


}
// ==========================================================================
// Content
// ==========================================================================

 
.breadcrumb-wrapper {
 
    // border-style: solid;
    // border-width: 1px 0;
    .breadcrumb {
        background: transparent;
        margin-bottom: 0;
        font-size: 1em;
        color: $link-color;
    }

    .breadcrumb > li + li:before {
        color: $link-color;
        content: "> ";
        padding: 0 5px;
       

    }

}

//
// Home
//


// ==========================================================================
// Sidebar 
// ==========================================================================
#sidebar {


} 

// ==========================================================================
// Layer Slider Quadrado
// ==========================================================================
  
    #slider-wrapper-quadrado{
        .container-fluid{
            padding:0;
            margin:0;
            z-index: 1;
            position: relative;
        }
        #layerslider-quadrado{
            .ls-slide{
                .btn1{
                    border:none;
                    background-color:
                    transparent; 
                    top:240px !important;
                    left:494px !important; 
                    font-style: normal;
                    color:#3864a9;
                    white-space: nowrap;
                    line-height: 1;
                    font-size:22px; 
                    font-weight: 100;
         
                    @media (max-width: 1199px) {
                        left:389px !important;
                        top:61% !important;
                        font-size:18px;  
                        // color:red;
                    }
                 
                    @media (max-width: 989px) {
                        top:60% !important;
                        left:42% !important;
                        font-size:16px;  
                    }



                }
                .btn2{
                    border:none;
                    background-color: transparent; 
                    top:240px !important;
                    left:496px !important; 
                    font-style: normal;
                    color:#3864a9;
                    white-space: nowrap;
                    line-height: 1;
                    font-size:22px; 
                    font-weight: 100;
                
                    @media (max-width: 1199px) {
                        left:407px !important;
                        top:61% !important;
                        font-size:18px;  
                        // color:red;
                    }
                 
                    @media (max-width: 989px) {
                        top:59.5% !important;
                        left:42.5% !important;
                        font-size:16px;  
                    }
                }
     
                .btn3{
                     border:none; 
                     background-color: transparent; 
                     top:40px !important;
                     left:470px !important;
                     font-style: 
                     normal;  
                     color:red; 
                     white-space: 
                     nowrap; 
                     line-height: 1; 
                     font-size:22px; 
                     font-weight: 100; 

                    @media (max-width: 1199px) {
                        left:402px !important;
                        top:11% !important;
                        font-size:18px;  
                        // color:red;
                    }
             
                    @media (max-width: 989px) {
                        top:10% !important;
                        left:41% !important;
                        font-size:16px;  
                    }
     
                }
 
            }
      
        }
    }
     
 





// ==========================================================================
// Sidebar
// ==========================================================================
#slider-wrapper{
    .container-fluid{
        padding:0;
        margin:0;
        z-index: 1;
        position: relative;
    }
    #layerslider{
        .ls-slide{
            .btn1{
                border:none;
                background-color:
                transparent; 
                top:240px !important;
                left:494px !important; 
                font-style: normal;
                color:#3864a9;
                white-space: nowrap;
                line-height: 1;
                font-size:22px; 
                font-weight: 100;
     
                @media (max-width: 1199px) {
                    left:389px !important;
                    top:61% !important;
                    font-size:18px;  
                    // color:red;
                }
             
                @media (max-width: 989px) {
                    top:60% !important;
                    left:42% !important;
                    font-size:16px;  
                }



            }
            .btn2{
                border:none;
                background-color: transparent; 
                top:240px !important;
                left:496px !important; 
                font-style: normal;
                color:#3864a9;
                white-space: nowrap;
                line-height: 1;
                font-size:22px; 
                font-weight: 100;
            
                @media (max-width: 1199px) {
                    left:407px !important;
                    top:61% !important;
                    font-size:18px;  
                    // color:red;
                }
             
                @media (max-width: 989px) {
                    top:59.5% !important;
                    left:42.5% !important;
                    font-size:16px;  
                }
            }
 
            .btn3{
                 border:none; 
                 background-color: transparent; 
                 top:40px !important;
                 left:470px !important;
                 font-style: 
                 normal;  
                 color:red; 
                 white-space: 
                 nowrap; 
                 line-height: 1; 
                 font-size:22px; 
                 font-weight: 100; 

                @media (max-width: 1199px) {
                    left:402px !important;
                    top:11% !important;
                    font-size:18px;  
                    // color:red;
                }
         
                @media (max-width: 989px) {
                    top:10% !important;
                    left:41% !important;
                    font-size:16px;  
                }
 
            }

 
            // .slide1-img1{
            //     top: 6.4% !important;
            //     left:40px !important;
            //     // white-space: nowrap;
            //     @media (max-width: 1199px) {
            //          top:7.4% !important;
            //         // color:red;
            //     }
             
            //     @media (max-width: 989px) {
            //          top:8% !important;
            //          left: 6% !important;
            //     }
            // }

            // .slide1-img2{
            //     top:7.9% !important;
            //     left:533px;
                
            //     // @media (max-width: 1199px) {
            //     //      top:8% !important;
            //     //     // color:red;
            //     // }
             
            //     @media (max-width: 1199px) {
            //           top: 29px !important;
            //           left: 48% !important;
                     
            //     }

            //     @media (max-width: 989px) {
            //            top: 11% !important;
            //            left: 48% !important;
                     
            //     }
 

            // }

 
            // .slide2-img1{
            //     top:7.1% !important;
            //     left:40px !important;
            //     // white-space: nowrap;
 
            //     @media (max-width: 1199px) {
            //          top:8% !important;
            //          left: 5% !important;
            //     }
            // }

            // .slide2-img2{
            //     top:-2.7% !important;
            //     left:507px !important;

             
            //     @media (max-width: 1199px) {
            //       top: -2% !important;
            //       left: 46% !important;
            //     }

            //     @media (max-width: 989px) {
            //         top: -4px !important;
            //         left: 46% !important;
                     
            //     }

            // }

  


 
            // .slide3-img1{
            //     top:7.1% !important;
            //     left:40px !important;
            //     // white-space: nowrap;
 
            //     @media (max-width: 1199px) {
            //         top: 9% !important;
            //         left: 5% !important;
            //     }
            // }

            // .slide3-img2{
            //     top: 10.3% !important;
            //     left: 627px !important;

             
            //     @media (max-width: 1199px) {
            //        top: 8.8% !important;
            //        left: 53% !important;
                 
            //     }

            //     @media (max-width: 989px) {
            //         top: 11% !important;
            //         left: 53% !important;
            //     }

            // }






        }
 

    }
}
 
 
  










// ==========================================================================
// Components
// ==========================================================================

//
// Fancy skip link
//
// Make it look a bit less "bare bones"
// Also includes focus suppression for the Chrome tabindex="-1" workaround
//
#skippy {
    display: block;
    padding: 1em;
    background-color: #f5f5f5;
    outline: 0;
}

#skippy .skiplink-text {
    padding: .5em;
    outline: 1px dotted;
}

#content:focus {
    outline: none;
}

//
// Pagination
//
.pagination-wrap {
    text-align: center;
    margin-top: 15px;
    .infinite-scroll & {
        display: none;
    }
}

//
// Share
//
.entry-share {
    a {
        color: #fff;
        border-radius: 20px;
        display: block;
        float: left;
        font-size: 1em;
        height: 2.5em;
        line-height: 2.5em;
        margin: 0 .5em 0 0;
        text-align: center;
        width: 2.5em;

        &:last-child {
            margin-right: 0;
        }

        &.facebook {
            background: #51a9a1;
            &:hover {
                background: darken(#51a9a1, 10%);
            }
        }
        
        &.twitter {
            background: #51a9a1;
            &:hover {
                background: darken(#51a9a1, 10%);
            }
        }
        
        &.gplus {
            background: #51a9a1;
            &:hover {
                background: darken(#51a9a1, 10%);
            }
        }
        
        &.pinterest {
            background: #51a9a1;
            &:hover {
                background: darken(#51a9a1, 10%);
            }
        }

        &.linkedin {
            background: #51a9a1;
            &:hover {
                background: darken(#51a9a1, 10%);
            }
        }
        
        &.email {
            background: #51a9a1;
            &:hover {
                background: darken(#51a9a1, 10%);
            }
        }

        &.whatsapp {
            background: #51a9a1;
            &:hover {
                background: darken(#51a9a1, 10%);
            }
        }
    }
}



 

// ==========================================================================
// Search Toggle
// ==========================================================================
.box-busca{
    margin: .8em 0em 0em 0em;
    form{
         input[type="submit"] {
            background-image: url(../../assets/images/lupa.png);
            background-repeat: no-repeat;
            text-indent: 0px;
            border: 0;
            width: 37px;
            margin: -63px 1px 1px 104px;
            background-color: transparent;
            &:focus, :active{
               outline:0; 
               background-color: transparent;
               border: none !important;

            }

         }

    }
}

.box-redes-sociais{
    padding: 16px 0px 0px 122px;
    
 
    @media (max-width: $screen-phone) {
        ._2p3a{
            width: 332px;
        }
    }

    @media (min-width: $screen-tablet) {
        padding: 14px 0px 0px 2px;
        // border: 3px solid orange;
        width: 8%;
    }




    @media (min-width: $screen-desktop) {
         padding: 14px 0px 0px 62px;
          width: 14%;
 
    }
    @media (min-width: $screen-lg) {
       padding: 16px 0px 0px 95px;
       // border:3px solid red;
    }






}

   
 

.search-form .form-group {
  float: right !important;
  transition: all 0.35s;
  width: 1px;
  height: 28px;
  background-color: transparent;
  box-shadow: 0;
 
    border-bottom: 3px solid #ccc;
    @media (min-width: $screen-tablet) {
        height: 28px;
        width: 133px !important;
    }
      @media (min-width: $screen-desktop) {
          float: right !important;
          transition: all 0.35s;
          width: 1px;
          height: 28px;
          background-color: transparent;
          box-shadow: 0;

 
    }
      @media (min-width: $screen-lg) {
          float: right !important;
          transition: all 0.35s;
          width: 1px;
          height: 28px;
          background-color: transparent;
          box-shadow: 0;
         
    }
  
}


.search-form  .form-control-feedback {

  width: 28px;
  height:28px;

}
.search-form  .form-control {

    @media (min-width: $screen-tablet) {

        padding-right: 0px;
        margin: 1px 30px 1px -32px;
    }

 
}



 
 
.search-form .form-group input.form-control {
  padding-right: 22px;
  margin: 1px 30px 1px -11px;
  border: 0 none;
  background: transparent;
  box-shadow: none;
  display:block;
    @media (min-width: $screen-tablet) {
        padding-right: 0px;
        margin: -3px 30px 1px -11px;
    }


    @media (min-width: screen-desktop) {
        border:4px solid red;
        padding-right: 1px ;
        margin: 0px 0px 1px -29px !important;
    }
     @media (min-width: screen-lg) {
        border:4px solid blue;
       
    }
}
.search-form .form-group input.form-control::-webkit-input-placeholder {
  display: none;
}
.search-form .form-group input.form-control:-moz-placeholder {
  /* Firefox 18- */
  display: none;
}
.search-form .form-group input.form-control::-moz-placeholder {
  /* Firefox 19+ */
  display: none;
}
.search-form .form-group input.form-control:-ms-input-placeholder {
  display: none;
}
.search-form .form-group:hover,
.search-form .form-group.hover {
  width: 100%;
  // border-radius: 4px 25px 25px 4px;
}
.search-form .form-group span.form-control-feedback {
  position: absolute;
  top: 1px;
  right: 1px;
  z-index: 2;
  display: block;
  width: 28px;
  height: 28px;
  line-height: 34px;
  text-align: center;
  color: #3596e0;
  left: initial;
  font-size: 14px;
}


// ==========================================================================
// Search Toggle
// ==========================================================================









// ==========================================================================
// Footer
// ==========================================================================

#footer {}

.footer-content {
    width: 103%;
    
   
        @media (min-width: $screen-phone)  {
             // width: 112%;
        }
        @media (min-width: $screen-tablet)  {
            width:123%
        }
        @media (min-width: $screen-desktop)  {
             width: 100%;
        } 
        @media (min-width: $screen-lg)  {
             width: 103%;
        } 


    background-color: $brand-quart;
    
    a {
        color: #fcfcfc;
        @media (min-width: $screen-phone)  {
            font-size: 1.1em; 
        }
        @media (min-width: $screen-tablet)  {
            font-size: 1.1em;
            padding-right: 1em; 
        }
        @media (min-width: $screen-desktop)  {
            font-size: 1.2em; 
            // padding-left: 2em;
        } 
        @media (min-width: $screen-lg)  {
            font-size: 1.3em; 
            // padding-left: 2em;
        } 
        // font-weight: 300;
        padding: 2px;
        line-height: 34px;

 
        &:hover {
            color: #fff;
        }
    }
    
    ul > li {
        opacity: 1.0;
    }

    ul  > li:hover {
        opacity: 0.8;
        background-color:rgba(255, 255, 255, 0.0784313725490196);
    }

 
   .list-group-item {
          padding: 10px 3px;
          font-size: .9em;
          font-weight: 100;
          width: 165px;
          background-color: transparent;
          border: 0px;
    }
 
    .widget {
        @media (min-width: screen-tablet) {
            margin-bottom: 2em;
        }
    }

    .widget-title {
        &:before {
            content: none;
        }
    }

    .footer-col {
         
        @media (max-width: 479px)  {
           margin:0em 0em 0em 1em;
           padding-left: 2em;
           padding-top: 0em;
        }
       
        @media (min-width: $screen-phone)  {
           margin:0em 0em 2em 1em;
           padding-left: 2em;
           padding-top: 0em;
        }

        @media (min-width: $screen-tablet)  {
           margin:0em 0em 0em 1em;
           // padding-left: 2em;
           padding-top: 0em;
        }

        @media (min-width: $screen-desktop)  {
           // padding-left: 2em;
         padding-left: 1em;
         padding-top: 3em;
           // margin-left: 2em;
        }
        @media (min-width: $screen-lg)  {
            // padding-left: 3em;
            padding-top: 3em;
            // margin-left: 2em;
        }

        .widget {

            width: 113%;
        }


    }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        font-size: 26px;
        margin-top: 0;
        color: white;
  
         font-weight: 100;
        // font-weight: bold;
        text-transform: uppercase;
    }
    h3 {
        
        font-size: 28px;
        @media (min-width: $screen-phone)  {
            font-size: 22px;
        }
    }

    h4 {
        
        font-size: 22px;

    }

    h4{
        a{
            color: $link-color;
            font-size: 18px;

        }
    }
    p{
        color: #fff;
        font-size: 1em;

    }  

    @media (max-width: $screen-phone)  {

        p{
           font-size: 16px; 
        }
    }

}

.site-info {
    font-size: 12px;
    font-family: Helvetica;
    background-color: #fff;
    position: relative;
    text-align: center;
    width: 100%;
    a{
       color: $link-color;
    }

    // @media (min-width: $screen-desktop) {
    //     font-size: 15px;
    // }
}



// ==========================================================================
// Slick Slider
// ==========================================================================
.slider {
    width: 100%;
    margin: 40px auto;
}
 
.slick-slide {
  margin: 0px 20px;
  button{
    background-color: transparent;
  }
}

.slick-slide img {
    width: 105%;
    border: 13px solid #f5f5f5;
    max-width: 110%;
    margin: 0 0 30px;
    opacity: 1.0;
    filter: alpha(opacity=100); /* For IE8 and earlier */
        &:hover,
        &:focus {
            // background-color: $brand-secondary;
            opacity: 0.8;
            filter: alpha(opacity=80); /* For IE8 and earlier */
            border: 13px inset #166d70;


        }

}
 
   
.slick-prev:before,
.slick-next:before {
    color: transparent;
    // background-color: transparent !important;
    // outline: 0;
}
.slick-prev {
  left: -16px;
  top: 32%;
  background: url(../images/arrow-left.png) no-repeat ;
  background-color: transparent !important;
  height:60px;
  outline: 0 !important; 
  border:0 !important; 
}
.slick-next {
  right: -33px;
  top: 32%;
  background: url(../images/arrow-right.png) no-repeat ;
  background-color: transparent !important;
  height:60px;
  outline:0 !important;
  border:0 !important;
}

 .slick-initialized .slick-slide {
 
  line-height: 0.4;

    @media (max-width: 989px) {
     line-height: 1.4;

    }

  
 
}



.slick-prev:hover,
.slick-prev:focus,
.slick-prev:active,
.slick-prev:visited,
{ 
    background-color: transparent;
    background: url(../images/arrow-left.png) no-repeat ;
    outline:0 !important;
    border:0 !important;
    height:60px;
}
.slick-next:hover,
.slick-next:focus,
.slick-next:active,
.slick-next:visited,
{ 
    background-color:  transparent;
    background: url(../images/arrow-right.png) no-repeat ;
    outline:0 !important;
    border:0 !important;
    height:60px;
}

// ==========================================================================
// Slick Slider
// ==========================================================================




.modal-backdrop {
    position: relative;
}







// ==========================================================================
// Contato
// ==========================================================================

#contato {
    outline:0;
 
    // }
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        font-weight: 100;
        color: #7b7b7b;
    }
     
    .form{
       
        @media (min-width: $screen-tablet)  {
            margin: 0em 0em 0em 0em;
            width: 82%;
        }    
        @media (min-width: $screen-desktop)  {
            margin: 0em 0em 0em 3em;
            width: 45%;
        }
        @media (min-width: $screen-lg)  {
            margin: 0em 0em 0em -4em;
        }
        .texto-empresa{
            font-size: 21px;
            text-align: justify;
        }
    }
    .form-control {
 
        @media (min-width: $screen-desktop)  {
            padding: 3px 10px;
        }
        @media (min-width: $screen-lg)  {
            padding: 3px 10px;
        }
  
    }

 
    .texto-empresa{
        font-size: 20px;
    }

    .entry-header{
        @media (min-width: $screen-tablet)  {
            margin: 0em 0em 0em 7em;
        }
        @media (min-width: $screen-desktop)  {
            margin: 0em 0em 0em 0em;
        }   
        @media (min-width: $screen-lg)  {
            margin: 0em 0em 0em 0em;
        } 

    }

    // @media (max-width: $screen-phone)  {

    //     .widget {
    //          margin-bottom: 2em;
    //          margin-top: 3em;

    //     }
 
    // }
    // @media (min-width: $screen-lg)  {

        .widget {
             // margin-bottom: 2em;
             // margin-top: 3em;

            @media (min-width: $screen-tablet)  {
                margin: 1em 0em 0em 7em; 
            }
            @media (min-width: $screen-desktop)  {
                margin: 1em 0em 0em 0em;
            }
            @media (min-width: $screen-lg)  {
                margin: 1em 0em 0em 0em; 
            }


        }
 
    // }


 }

 
// ==========================================================================
// Contato
// ==========================================================================



// ==========================================================================
// Representantes
// ==========================================================================

#representantes {
    &:before {
        content: "";
        background: url(../images/representantes_lugo_brinquedos.jpg) no-repeat center center;
        bottom: 0;
        position: absolute;
        height: 522px;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
    }
    .figure{
        margin-top: 3.5em;
    }
 
    h1{
        color: $link-color;
        font-size: 25px;
        text-transform: uppercase;

    }

    p{
        color: $link-color;
        font-size: 13px;
        margin: 0 0 -45px;
    }
    
    @media (max-width: 1199px){
        .figure{
            margin-top: 6.5em;
        }  
    }
    // @media (max-width: $screen-tablet)  {
    //     .figure{
    //         margin-top: 6.5em;
    //     }

    // }

    @media (max-width: $screen-phone)  {
 

        p{
            margin: 0 0 -45px;
        }
        .widget {
            padding-top: 3em;
             
        }
 
    }



 }

 
// ==========================================================================
// Ser Representantes
// ==========================================================================


#serrepresentantes {
    &:before {
        content: "";
        background: url(../images/representantes_lugo_brinquedos2.jpg) no-repeat center center;
        bottom: 0;
        position: absolute;
        height: 522px;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
    }
    .figure{
        margin-top: -0.5em;
    }
 
    h1{
        color: $link-color;
        font-size: 25px;
        text-transform: uppercase;

    }

    p{
        color: $link-color;
        font-size: 13px;
        margin: 0 0 -45px;
    }
    
    @media (max-width: 1199px){
        .figure{
            margin-top: 3.5em;
        }  
    }
    // @media (max-width: $screen-tablet)  {
    //     .figure{
    //         margin-top: 6.5em;
    //     }

    // }

    @media (max-width: $screen-phone)  {
 

        p{
            margin: 0 0 -45px;
        }
        .widget {
            padding-top: 3em;
             
        }
 
    }



 }

 
// ==========================================================================
// Ser Representantes
// ==========================================================================




// ==========================================================================
// Downloads
// ==========================================================================

#downloads {
    h1{
        color: $link-color;
        font-size: 25px;
        text-transform: uppercase;
        padding: 0 0 0 2em;

    }
}

 
// ==========================================================================
// Downloads
// ==========================================================================



// ==========================================================================
// Onde Comprar
// ==========================================================================
 
.dropdown-revendedores{
    margin-bottom: 27px; 
    padding: 0 15px 0 0; 
}
.ajust-drop-revend{
     padding: 0 15px 0 0; 
}
.panel-body-revend{
    padding: 0px;
     border: 3px solid #ffd89d;
}
.panel-default-color-border{
    border-color: #ffffff;
}

.p-panel-revend{
    margin: 0 0 1px;
    text-transform: uppercase;
    font-size: 11px;
}
.a-panel-revend{
    color:#636363 !important;
}
.hr-revend{
    margin-top: 5px;
    margin-bottom: 5px;
    border: 0;
    border-top: 1px solid #e4e4e4;
}


.cidade-estado_h3{
    color: #dca60e;
    font-size: 1.5em;
    text-transform: uppercase;
    padding: 0;
} 

@media (max-width: 2999px)  {
   .listar-todos {
        float: right;
        // border: 1px solid green;
        /* position: relative; */
        width: 35%;
        margin-top: -1em;
        span{
            color: #8c8c8c;
            text-transform: uppercase;
            font-family: Helvetica, Arial;
            font-size: 11px;

        }

   }
}

@media (max-width: 999px)  {
   .listar-todos {
       float: right;
       width: auto;
   }
}

@media (max-width: 1200px)  {
    .cidade-estado_h3{
        font-size: 1em; 
        display: inline-block;
         margin-top: 90px;
    }
}

@media (max-width: $screen-phone)  {
    .cidade-estado_h3{
        color: #dca60e;      
        margin-top: 25px;
        font-size: 1em; 
        text-transform: uppercase;
        display: inline-block;
    }

   .listar-todos {
        float: right;
        width: auto;
   }
}
// ==========================================================================
// Onde Comprar
// ==========================================================================
