// ==========================================================================
// Product list
// ==========================================================================

 
.title-toolbar{

    h1{
        color:$brand-quart;
        margin: -.8em 0em 0em 7.5em;

        img{
            margin:0 0 8px -19px;
        }

    }
    h2{
        color:$brand-quart;
        margin: 0em 0em 0em 6.9em;
        
    }
}







.barra{
   background: url(../images/barra-categoria.png) no-repeat left;
   height:.2em;
}

#products {
    .product {
        float: left;
        margin: 0 2% 0em 0;
        padding: 0;
        position: relative;
        margin-left: 0;
    }

 
 
    &,
    &.columns-3,
    &.columns-6,
    &.columns-5 {
        .product {
            width: 48%;
 
            @media (max-width: $screen-phone) {
                margin-right: 0;

                &:nth-child(2n) {
                    float: right;
                    clear: none !important;
                }
            }
        }

        @media (max-width: $screen-phone) and (orientation: landscape) {
            .product {
                width: 30.65%;

                &:nth-child(3n+1) {
                    clear: both;
                }

                &:nth-child(3n+3) {
                    margin-right: 0;
                }
            }
        }

        @media (min-width: $screen-phone) and (max-width: 991px) {
            .product {
                width: 31.65%;

                &:nth-child(3n+1) {
                    clear: both;
                }

                &:nth-child(3n+3) {
                    margin-right: 0;
                }
            }
        }
    }

    @media (min-width: $screen-desktop) {
        .product {
            width: 23%;

            &.first {
                clear: both;
            }

            &.last {
                margin-right: 0;
            }
        }

        &.columns-3 {
            .product {
                width: 30.75%;
            }
        }

        &.columns-5 {
            .product {
                width: 16.95%;
            }
        }

        &.columns-6 {
            .product {
                width: 13.5%;
            }
        }

        &.columns-7 {
            .product {
                width: 11.02%;
            }
        }

        &.columns-8 {
            .product {
                width: 9.17%;
            }
        }
    }
 
    .product,
    .product-item {
        img {
            border: 0px solid #f0efef;
            max-width: 100%;
            opacity: 1.0;
            filter: alpha(opacity=100); /* For IE8 and earlier */
            -webkit-transition: all 200ms ease-in;
            -webkit-transform: scale(1); 
            -ms-transition: all 200ms ease-in;
            -ms-transform: scale(1); 
            -moz-transition: all 200ms ease-in;
            -moz-transform: scale(1);
            transition: all 200ms ease-in;
            transform: scale(1);   
                &:hover,
                &:focus {
                    // background-color: $brand-secondary;
                    opacity: 1.0;
                    filter: alpha(opacity=100); /* For IE8 and earlier */
                    border: 1px solid $brand-quart;
                    // max-width: 110.5%; 
                    z-index: 2;
                    -webkit-transition: all 100ms ease-in;
                    -webkit-transform: scale(0.5);
                    -ms-transition: all 100ms ease-in;
                    -ms-transform: scale(1.1);   
                    -moz-transition: all 100ms ease-in;
                    -moz-transform: scale(1.1);
                    transition: all 100ms ease-in;
                    transform: scale(1.1);

                }
            // border-radius: 20px;
        }
    }
 
    .product-meta {
        min-height: 110px;
        @media (min-width: $screen-desktop) {
            min-height: 80px;
            padding: 0 0 0 0em;
        }

        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6 {
            margin: 0; 

        }

        .more {
            text-decoration: underline;
        }

        .sku {
            color: $brand-quart;
            display: block;
            font-size: 118%;
            font-weight:800;
            text-align:left;
        }

 

        h4{
            a{
                color:$brand-quart;
                font-weight:800;
                text-transform: uppercase;
            }
        }

        .product-title {
            // font-size: inherit;
            font-weight:800;
            margin-top: .3em;
            font-size: 118%;
            text-align:left;
            font-weight:800;


        }

        @media (max-width: $screen-phone)  {
            .sku {
                font-size: 1em;
            }
            .product-title {
                font-size: 1em;
            }

        }
    }
}
.sku-related {
    color: $link-color;
    font-size: 129%;
}

 
.product-title-related {
    a{
        color: #4e4e4e ;
        font-size: 87%;
    }
 
}
@media (max-width: $screen-phone)  {
    .sku-related {
        color: $link-color;
        font-size: 91%;
     
    }
    .slick-initialized .slick-slide {
      line-height: 0.7;
    }

    
}

.container{
    // border: 1px solid red;
}


// ==========================================================================
// Single
// ==========================================================================

#single-product{
    outline:0;
    margin-top:-5em;
    .product-title{
         color: $brand-quart;
         font-size: 27px;
         // text-transform: uppercase;
        @media (max-width: 474px)  {
            // margin:  0em 0em 0em 0em;

        }

        @media (min-width: $screen-phone)  {
            // margin:  0em 4em 0em 0em;

        }

        @media (min-width: $screen-tablet)  {
            margin:  0em 0em 0em -0.6em;

        }

        @media (min-width: $screen-desktop)  {
            margin:  0em 0em 0em 0em;

        }

        @media (min-width: $screen-lg)  {
            margin:  0em 0em 0em 1.8em;

        }

    }
    .ref{
       font-size: 35px; 
       color: $brand-quart;
       font-weight: 800;
       margin: 0;
        @media (max-width: 479px)  {
            // margin: 0em 0em 0em -.5em;

        }
        @media (min-width: $screen-phone)  {
            // margin: 0em 0em 0em -1.7em;

        }
        @media (min-width: $screen-tablet)  {
            margin: 0em 0em 0em -.5em;

        }
        @media (min-width: $screen-desktop)  {
            margin: 0em 0em 0em 0em;
        }
        @media (min-width: $screen-lg)  {
            margin: 0em 0em 0em 1.4em;

        }
 
 

    }
   .text-primary{
   
        color:#575757;
        font-size: 17px;
        margin: 8px 0em 0em -11px;
        // line-height: 0em;
 
   }
    .categoria-titulo{

        h1{
            color:$brand-quart;
            margin: -.8em 0em 0em 7.5em;
           
            @media (max-width:479px)  {
                margin:-1em 0em 0em 2em;
                  font-size: 2em;
 

            }    

            @media (min-width: $screen-phone)  {
                margin: -1em 0em 0em 2em;

            }


            @media (min-width: $screen-tablet)  {
                margin: -1em 0em 0em 5em;

            }
            @media (min-width: $screen-desktop)  {
                margin: -1em 0em 0em 6em;

            }
            @media (min-width: $screen-lg)  {
                margin: -1em 0em 0em 7em;

            }

 
 
            img{
                margin:0 0 8px -12px;
                @media (max-width:479px)  {
                margin:0 0 0px -7px;


            }  
 

            }

        }
        h2{
            color:$brand-quart;
            margin: 0em 0em 0em 6.9em;
            
                
            @media (max-width:479px)  {
                margin: 0em 0em 0em 0em;


            }    
       

            @media (min-width: $screen-phone)  {
                margin: 0em 0em 0em 0em;

            }
             
            @media (min-width: $screen-tablet)  {
                margin: 0em 0em 0em 3.9em;
            }
            @media (min-width: $screen-desktop)  {
                margin: 0em 0em 0em 5.3em;
            }
 
            @media (min-width: $screen-lg)  {
                margin: 0em 0em 0em 6.3em;
            }

        }
    }
    .categoria-titulo-related{

         h1{
            color:$brand-quart;
            margin: 0em 0em 0em 0em;
            font-size: 33px;
            span{
                font-size: .7em;
            }
            img{
                margin:0 0 8px -5px;
            }

        }

    }
}

 
@media (max-width: $screen-phone)  {
    .product-title-related a {
        font-size: 62%;
    }
}

.product-single {
    #main-image {
        // border-radius: 20px;
        margin-bottom: 1.5em;
        // border: 15px solid #f5f5f5;
        overflow: hidden;
        width: 95%;
        img {
            // margin: 0 auto;
        }
    }
 
 
    #product-thumbs {
        // margin-bottom: -8em;
          
        position: absolute;
        @media (max-width: 767px) {
            width: 117%;
            margin: -2.6em 0em 0em -2em;
             position: relative;
           display: inline-block;

        } 

        @media (min-width: $screen-phone) {
            width: 100%;
            margin: -2.6em 0em 0em -1em;
            position: relative;

        }
        
        @media (min-width: $screen-tablet) {
            width: 45%;
            margin:-5.6em 0em 0em -2em;
             position: absolute;

        }
 
        @media (min-width: $screen-desktop) {
            width: 45%;
            margin:-5.8em 0em -2em 0em;
             position: absolute;

        }

        @media (min-width: $screen-lg) {
            width: 66%;
            margin:-5.8em 0em -2em 0em;

        }


        .owl-thumb-item {
            // border-radius: 20px;
             overflow: hidden;
            z-index: 999;
            position: relative;
        }
    }

    .owl-thumb-item {
        border: 1px solid #e5e5e5;
        cursor: pointer;
        display: inline-block;
        margin: 0 1em 1em 0;
        text-align: center;

        &:last-child {
            margin-right: 0;
        }
        
        img {
            width: 95px;
            border: 0px solid #f5f5f5;
           
            @media (max-width: 468px) {
               width: 68px;
            } 
            @media (min-width: $screen-phone) {
               width: 99px;
            }            
            @media (min-width: $screen-tablet) {
               width: 138px;
            }
            @media (min-width: $screen-desktop) {
               width: 58px;
            }          
            @media (min-width: $screen-lg) {
               width: 110px;
            }

        }
    }

    .product-meta {
        .product-title {
            font-weight: normal;

        }
    }

    .barra_prod{
         margin: 17.7em 0em 0em 54em;
         position: absolute;
    

        @media (min-width: $screen-tablet) {
            margin: 17.7em 0em 0em 54em;
            position: absolute;
        }  

        @media (min-width: $screen-desktop) {
            margin: 17.7em 0em 0em 54em;
            position: absolute;
           
        }
        @media (min-width: $screen-lg) {
            margin: 17.7em 0em 0em 54em;
            position: absolute;
                 
        }

    }

    .summary {  
    
        @media (min-width: $screen-tablet) {
           margin: 0 0px 0px 0em;
        }  

        @media (min-width: $screen-desktop) {
           margin: 0 0px 0px 0em;
           width: 40%;
        }
         @media (min-width: $screen-lg) {
            margin: 0 0px 0px 8em;
            width: 36%;
        }
 
        // width: 28em;
        background-color:#efefef;
        .entry-summary {}

        .description {
        
            @media (max-width: 479px) {
                 margin-left: .4em;
            }  
            @media (min-width: $screen-phone) {
                 margin-left: .4em;
            } 

   
            margin-bottom: .1em;
            
            + & {
                margin-bottom: 4em;
            }
        }
        img{
            display: inline-block;
            
            // @media (min-width: $screen-phone) {
            //     margin: 0em 1em .4em 0em;
            // }  
            @media (max-width: 479px) {
                margin: .1em 0em 0.2em 0em;
            }   
            @media (min-width: $screen-phone) {
                margin: .7em 1em 0.4em 0em;
            }    
    
            @media (min-width: $screen-tablet) {
                margin: .3em .2em .3em 0em;
            } 
            @media (min-width: $screen-desktop) {
                margin: 1em 0em 1em .1em;
            }
            @media (min-width: $screen-lg) {
                 margin: 1em 0em 1em .1em;
            }

        }
 
    }

    .entry-share {
        margin: 2em 40px 0;
        @media (max-width: 479px) {
            margin: 1.4em 0px 0;
        }          
        @media (min-width: $screen-phone) {
            margin: 1.4em 0px 0;
        }          
        @media (min-width: $screen-tablet) {
            margin: 1.4em 12px 0;
        }   
        @media (min-width: $screen-desktop) {
            margin: 1.4em 69px 0;
        }   
        @media (min-width: $screen-lg) {
             margin: 1.4em 44px 0;
        }
 


    }
    .share-box{
        margin: 0 0 0 7.1em;
  
       
        @media (min-width: $screen-tablet) {
            margin: 18px 8px -46px 41.1em;
        } 
        @media (min-width: $screen-desktop) {
            margin: 65px -304px 1px 0em;
        }
        @media (min-width: $screen-lg) {
             margin: 15px 39px 3px 0.8em;
        }
 

    }

    .refs-box{
        margin: 0 0 0 6em;
        
        @media (max-width: 479px) {
              margin: -13px -91px 0 2em;
              padding: 0px 43px 2px 2px;
              width: 115%;
              display: inline-flex;
             
        } 


 

        @media (min-width: $screen-phone) {
              margin: -13px -91px 0 2em;
              padding: 0px 43px 2px 2px;
              width: 115%;
              display: inline-flex;
             
        } 

        @media (min-width: $screen-tablet) {
            margin: 78px -30.7em 0px 0em;
            display: inline-block;
            width: 55.2%;
        }  
        @media (min-width: $screen-desktop) {
              margin: -4px -8.1em -2px 0em;
              display: inline-block;
              width: 51%;

        }
        @media (min-width: $screen-lg) {
            margin: 8px -12.6em 0px 0em;
            width: 51%;
            display: inline-block;
           
        }
        h4{
            font-size: 1em;
            // width: 29.33333%;
              // margin-top: 0.9em;
              // margin-left: 0em;
        @media (min-width: $screen-tablet) {
             margin: 13px 14px 0px 0em;
            padding: 0px 0px 0px 0px;
 
        }  
        @media (min-width: $screen-desktop) {
            margin: 0px 10px 0px 0em;
            padding: 0px 0px 0px 0px;

        }
        @media (min-width: $screen-lg) {
    margin: 0px 1px 0px 0em;
    padding: 2px 0px 0px 0px;
           
        }
 


            a{
                color: #ffffff;
                text-decoration: none;
                padding: .1em;

 
                 margin: .1em;


                background-color: $brand-quart;
                
            }
        }
        div{
            a{
                font-size:1.4em;
                color:$brand-quart;
                font-weight: bold;
                margin: 1em 5em 0em 0em;
                
                @media (min-width: $screen-phone) {
                    margin: 9px 0px 0px 0em;
         
                }      

                @media (min-width: $screen-tablet) {
                    margin: 9px  2.2em  0px 0em;
         
                } 
                 @media (min-width: $screen-desktop) {
                    margin: 1em 3.6em 0em 0em;

                }
                 @media (min-width: $screen-lg) {
                    margin: 1em 6.6em 0em 0em;
 
                    // border: 1px solid red;
                }
 
            

 




            }
 
        }

    }
 
}
 
 
    .thumb-related{
        @media (min-width: $screen-phone) {
            margin: 9px 0px 0px 0em;
 
        }      

        @media (min-width: $screen-tablet) {
           margin: 0px -28px 0px 0em;
           width: 25.4em;
 
        } 
         @media (min-width: $screen-desktop) {
            margin: 23px 0px 0px 5.7em;
            width: 28.4em;

        }
         @media (min-width: $screen-lg) {
            margin: 9px 0px -13px 0.8em;
            width: 36%;
            padding: 0;
            // border: 1px solid red;
        }
 

        // display: -webkit-flex;
        // display: flex;
        img{
            border:2px solid $brand-quart;
            margin:.3em;
            
            // height: 93px;
            @media (max-width: 479px) {
                  width: 29%;
            }
            @media (min-width: $screen-phone) {
                  width: 31%;
            }

            @media (min-width: $screen-tablet) {
                 width: 30%;
            }
            @media (min-width: $screen-desktop) {
               width: 31%;

            }
            @media (min-width: $screen-lg) {
               width: 31%;

            }
        


        }
        h5{
        @media (max-width: 479px) {
             margin: 13px 0px 6px 20%;
        } 
        @media (min-width: $screen-phone) {
             margin: 0 0 0 31%;
        }  
 
        @media (min-width: $screen-tablet) {
            margin:1px 0 0 1.8em;
        }  
 
        @media (min-width: $screen-desktop) {
            margin:0 0 0 5.6em;
        }
         @media (min-width: $screen-lg) {
            margin:0 0 0 6.6em;
        }
  
            color:$brand-quart;
            font-weight: bold;
            margin: 0 0 0 6.8em;
        }
    }

// ==========================================================================
// Feature products
// ==========================================================================
.widget-feature-products {
    li {
        margin-bottom: 3em;

        a {
            display: block;
        }

        img {
            float: left;
            margin-right: 15px;
            width: 60px;
            height: auto;
            border: 1px solid #e5e5e5;
            border-radius: 10px;
        }

        .sku {
            color: $text-color;
            font-size: 85%;
        }

        .product-title {
            display: block;
        }
    }
}
 

#relateds{
    background-color: rgb(255, 255, 255);
    .title{
        color: $link-color;
        font-size: 10px;
        line-height: .5em;
        margin: .6em 1.7em;
    }
    


    .barra{
        margin-left:2em;
    }

    .product,
    .product-item {
        img {
            border: 13px solid #f5f5f5;
            max-width: 110%;
            opacity: 1.0;
            filter: alpha(opacity=100); /* For IE8 and earlier */

            -webkit-transition: all 200ms ease-in;
            -webkit-transform: scale(1); 
            -ms-transition: all 200ms ease-in;
            -ms-transform: scale(1); 
            -moz-transition: all 200ms ease-in;
            -moz-transform: scale(1);
            transition: all 200ms ease-in;
            transform: scale(1);   
                &:hover,
                &:focus {
                    // background-color: $brand-secondary;
                    opacity: 0.8;
                    filter: alpha(opacity=80); /* For IE8 and earlier */
                    border: 13px solid #eeeeee;
                    // max-width: 110.5%;
                    
                    box-shadow: 0px 0px 150px #000000;
                    z-index: 2;
                    -webkit-transition: all 200ms ease-in;
                    -webkit-transform: scale(1.5);
                    -ms-transition: all 200ms ease-in;
                    -ms-transform: scale(1.5);   
                    -moz-transition: all 200ms ease-in;
                    -moz-transform: scale(1.5);
                    transition: all 200ms ease-in;
                    transform: scale(1.5);

                }
            // border-radius: 20px;
        }
    }


}



 