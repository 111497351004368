// Mixins
// --------------------------------------------------

//
// Transiton
//
@mixin transition( $val, $val2: "" ) {
	-webkit-transition: $val, $val2;
	-moz-transition:$val, $val2;
	-o-transition:$val, $val2;
	-ms-transition:$val, $val2;
	transition:$val, $val2;
}

//
// Border radius
//
@mixin border-radius($radius : 4px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

//
// Transform
//
@mixin transform($val) {
	-webkit-transform: $val;
	-moz-transform: $val;
	-ms-transform: $val;
	transform: $val;
}

@mixin transform-origin($val) {
	-webkit-transform-origin: $val;
	-moz-transform-origin: $val;
	-ms-transform-origin: $val;
	transform-origin: $val;
}

@mixin transform-style($val) {
	-webkit-transform-style: $val;
	-moz-transform-style: $val;
	-ms-transform-style: $val;
	transform-style: $val;
}

@mixin perspective($val) {
	-webkit-perspective: $val;
	-moz-perspective: $val;
	-ms-perspective: $val;
	perspective: $val;
}

@mixin perspective-origin($val){
	-webkit-perspective-origin: $val;
	-moz-perspective-origin: $val;
	-ms-perspective-origin: $val;
	perspective-origin: $val;
}

// rotate
@mixin rotate( $val: -2deg ) {
	@include transform(rotate($val));
}

// scale
@mixin scale($scale) {
	@include transform(scale($scale));
}

// translate
@mixin translate($x, $y) {
	@include transform(translate($x, $y));
}

// skew
@mixin skew($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}

// clip path
@mixin clip-path($clip) {
  -webkit-clip-path: $clip;
  -moz-clip-path: $clip;
  -ms-clip-path: $clip;
  clip-path: $clip;
}

// center vertically and/or horizontally an absolute positioned element
@mixin center( $horizontal: true, $vertical: true ) {
    position: absolute;
    @if ($horizontal and $vertical) {
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
    } @else if ($horizontal) {
        left: 50%;
        @include transform(translate(-50%, 0));
    } @else if ($vertical) {
        top: 50%;
        @include transform(translate(0, -50%));
    }
}

// antialiasing mode font rendering
@mixin font-smoothing {
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

// appearance
@mixin appearance($val) {
	-webkit-appearance: $val;
  	-moz-appearance: $val;
  	-ms-appearance: $val;
  	-o-appearance: $val;
  	appearance: $val;
}

// placeholder
@mixin input-placeholder {
  &::-webkit-input-placeholder {
  	@content
  }
  &:-moz-placeholder {
  	@content
  }
  &::-moz-placeholder {
  	@content
  }
  &:-ms-input-placeholder {
  	@content
  }
}

//
// Animations / keyframes
//
@mixin animate($name, $duration, $iteration, $direction: "", $delay: "") {
	-webkit-animation-duration: $duration;
	   -moz-animation-duration: $duration;
	     -o-animation-duration: $duration;
		    animation-duration: $duration;

	-webkit-animation-iteration-count: $iteration;
	   -moz-animation-iteration-count: $iteration;
	     -o-animation-iteration-count: $iteration;
	        animation-iteration-count: $iteration;

	-webkit-animation-name: $name;
	   -moz-animation-name: $name;
	     -o-animation-name: $name;
	        animation-name: $name;

	-webkit-animation-direction: $direction;
	   -moz-animation-direction: $direction;
	     -o-animation-direction: $direction;
		    animation-direction: $direction;

    -webkit-animation-delay: $delay;
       -moz-animation-delay: $delay;
         -o-animation-delay: $delay;
            animation-delay: $delay;
}

// keyframes
@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}


// ANIMATIONS

// to bottom from top keyframes
@include keyframes(toBottomFromTop) {
	49% {
        @include transform(translateY(100%));
    }
    50% {
        opacity: 0;
        @include transform(translateY(-100%));
    }
    51% {
        opacity: 1;
    }
}

// to bottom from top
@mixin toBottomFromTop {
	@include animate(toBottomFromTop, .3s, forwards, initial);
}

// spin rotate keyframes
@include keyframes(spin) {
	from {
        @include rotate(0);
    }
    to {
        @include rotate(360deg);
    }
}

// spin rotate
@mixin spin {
	@include animate(spin, .5s, forwards, initial);
}

// float
@include keyframes(flat) {
    0% {
        @include transform(translateY(0));
    }

    50% {
        @include transform(translateY(-6px));
    }

    100% {
        @include transform(translateY(0));
    }
}

@mixin float-y {
    @include animate(flat, 1.3s, infinite, linear);
}

.float-y {
    @include float-y;
}

// animation for menu
@include keyframes(anim-trinculo) {
    50% {
        opacity: 0;
        color: #929292;
        @include transform(translate3d(100%, 0, 0));
    }

    51% {
        opacity: 0;
        color: darken($brand-primary, 10%);
        @include transform(translate3d(-100%, 0, 0));
    }

    75% {
        opacity: 1;
        @include transform(translate3d(5px, 0, 0));
    }

    100% {
        opacity: 1;
        color: darken($brand-primary, 10%);
        @include transform(translate3d(0, 0, 0));
    }
}

// naveffect
@mixin naveffect {
    @include animate(anim-trinculo, .6s, forwards);
}

// svg dash drawing
@keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@mixin dashdrawing {
    @include animate(dash, 2s, ease-in-out, forwards, .5s);
}