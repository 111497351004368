/* =Core Styles
-------------------------------------------------------------- */

/* Fix pagination */
ul.page-numbers {
	@extend .pagination;

	> li > span.current {

		&,
		&:hover,
		&:focus {
			z-index: 2;
			color: $pagination-active-color;
			background-color: $pagination-active-bg;
			border-color: $pagination-active-bg;
			cursor: default;
		}
	}
}

/* Fix Foms */
input[type="submit"],
input[type="reset"],
button {
	@extend .btn;
	@extend .btn-default;
}

.form-radio-group .radio {
	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}
