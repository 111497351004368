// ==========================================================================
// Theme Style Main
// ==========================================================================

/*!
Author URI: http://pitico.com.br/
Description: Main stylesheet.
Author: Pitico Studio
*/

@import
        "layout/variables",                            // Theme          Custom Variables
        "bootstrap/bootstrap",                  // Bootstrap      Styles Core
        "bootstrap/jasny/jasny-bootstrap",      // Jasny          Styles Core
        "core/core",                            // Core HTML      Styles Core
        "core/mixins",                 			// Core Mixins    Styles Core
        "core/widgets",              			// Widgets        Styles Core
        "core/spacers",                         // Core Mixins    Styles Core
        "core/utilities",                       // Utilities      Styles Core
        "font-awesome/font-awesome", 			// Font Awesome   Styles Core
        "layout/layout",                    			// Theme          Custom Layout
        "layout/products"                              // Theme          Products
;
