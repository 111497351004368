//
// General
//
.widget {
    display: block;
    margin-bottom: 3em;
    @extend .clearfix;

    // lists
    &.widget-list ul > li {
        margin-bottom: .75em;
    }
}

// Title
#sidebar {
    .widget-title {
        font-size: $font-size-base;        
    }
}

.widget-title {
    margin-bottom: 1em;
    position: relative;

    > span:first-child {
        font-style: italic;
        font-weight: normal;
    }

    .after {
        color: inherit;
        display: block;
        font-size: 75%;
        margin-top: 10px;

        &:before,
        &:after {
            font-family: FontAwesome;
            font-size: 65%;
            margin: 0 5px;

        }

        &:before {
            content: "\f111" "  \f111" "  \f111";
        }

        &:after {
            content: "\f111" "  \f111"  "  \f111";
        }
    }

    &.text-center {
    	&:after {
    		margin: 0 auto;
    		right: 0;
    	}
    }

    &.text-right {
    	&:after {
    		left: auto;
    		right: 0;
    	}
    }
}

//
// List
//
.widget-list {
    ul li {
        &:before {
            content: "\f054";
            color: $brand-tertiary;
            font-family: FontAwesome;
            font-size: 80%;
            margin-right: .5em;
        }

        a {
            margin-bottom: 1em;
        }
    }
}

//
// Interactive box
//
.widget-interactive-box {

}

.widget-box-shadow {
    .widget-title {
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 2em;
    }

    .shadow {
        display: block;
        height: 25px;
        -webkit-filter: blur(5px);
        filter: blur(10px);
        background: rgba(0,0,0,0.35);
        width: 65%;
        margin: 0 auto;
        border-radius: 50%;
        @include transition-property(all, ease-in-out);
        @include transition-duration(.5s);
    }

    &:hover {
        .shadow {
            width: 55%;
        }
    }
}

//
// Google maps
//
#save-widget {
    font-family: $font-family-base;
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
    background-color: white;
    padding: 10px;
    font-size: 13px;
    margin: 15px;

    p:last-child {
        margin-bottom: 0;
    }

    img {
        max-width: 100%;
    }
}

//
// Box colors products
//
.box-color {
    display: block;
    float: left;
    margin-right: .7em;
}

//
// Google maps
//
.infoBox {
    > img {
        z-index: 2;
        position: absolute !important;
        right: 0;
        text-align: center;
        padding: 10px;
    }

    .arrow-down {
        width: 20px;
        height: 10px;
        background: url(../images/arrow-down.png) top center no-repeat;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
}

.gm-infowindow {
    font-family: $font-family-sans-serif;
    // max-width: 300px;
    max-height: 180px;
    position: relative;
    background: rgb(255, 255, 255);
    font-size: $font-size-base - 3;
    border-radius: 3px;
    color: #aaa;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(50,50,50,0.3);
    -moz-box-shadow:    0px 3px 6px 0px rgba(50,50,50,0.3);
    box-shadow:         0px 3px 6px 0px rgba(50,50,50,0.3);

    #info-closer {
        position: absolute;
        width: 18px;
        height: 18px;
        z-index: 999999;
        cursor: pointer;
        top: 6px;
        right: 6px;
        text-align: center;
        color: #fff;

        .fa {
            color: white;
        }
    }

    .infowindow-title {
        background: $brand-primary;
        color: white;
        font-size: 13px;
        font-weight: bold;
        padding: 5px 10px;
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 30px;
        border-radius: 3px 3px 0 0;

        .fa {
            color: white;
        }
    }

    .infowindow-content {
    }

    .info-contact-title {
        border-top: 1px solid #ccc;

        b {
            font-weight: 900;
        }
    }

    b, i.fa {
        color: #555;
    }
}

// Cluster
.cluster > div,
.marker-cluster > div {
    font-family: $font-family-sans-serif !important;
}

.cluster > div:hover:before,
.marker-cluster > div:hover:before {
    -webkit-box-shadow: 0 0 0 3px $brand-secondary;
    -moz-box-shadow: 0 0 0 3px $brand-secondary;
    box-shadow: 0 0 0 3px $brand-secondary;
}

.cluster > div:before,
.marker-cluster > div:before {
    -moz-transition: all 0.4s cubic-bezier(0.42, 0, 0.06, 0.99) 0s;
    -o-transition: all 0.4s cubic-bezier(0.42, 0, 0.06, 0.99) 0s;
    -webkit-transition: all 0.4s cubic-bezier(0.42, 0, 0.06, 0.99);
    -webkit-transition-delay: 0s;
    transition: all 0.4s cubic-bezier(0.42, 0, 0.06, 0.99) 0s;

    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;

    background-color: $brand-primary;
    border: 2px solid lighten($brand-primary, 15%);
    content: "";
    height: 30px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 30px;
    z-index: -1;

    -webkit-animation: as-basic-fast 2s linear infinite;
    -moz-animation: as-basic-fast 2s linear infinite;
    animation: as-basic-fast 2s linear infinite;
}

@keyframes as-basic-fast {
    0%, 100% {
        -webkit-box-shadow: 0 0 0 2px lighten($brand-primary, 15%);
        -moz-box-shadow: 0 0 0 2px lighten($brand-primary, 15%);
        box-shadow: 0 0 0 2px lighten($brand-primary, 15%);
    }
    50% {
        -webkit-box-shadow: 0 0 0 3px lighten($brand-primary, 15%);
        -moz-box-shadow: 0 0 0 3px lighten($brand-primary, 15%);
        box-shadow: 0 0 0 3px lighten($brand-primary, 15%);
    }
}

@-webkit-keyframes as-basic-fast {
    0%, 100% {
        -webkit-box-shadow: 0 0 0 2px lighten($brand-primary, 15%);
        -moz-box-shadow: 0 0 0 2px lighten($brand-primary, 15%);
        box-shadow: 0 0 0 2px lighten($brand-primary, 15%);
    }
    50% {
        -webkit-box-shadow: 0 0 0 3px lighten($brand-primary, 15%);
        -moz-box-shadow: 0 0 0 3px lighten($brand-primary, 15%);
        box-shadow: 0 0 0 3px lighten($brand-primary, 15%);
    }
}