// Utilities styles theme
// --------------------------------------------------

//
// Texts transforms
//
.text-transform-none {
	text-transform: none;
}

//
// Headings
//
.text-bold-900 {	font-weight: 900; }
.text-bold-800 {	font-weight: 800; }
.text-bold-700 {	font-weight: 700; }
.text-bold-500 {	font-weight: 500; }
.text-bold-400 {	font-weight: 400; }
.text-bold-300 {	font-weight: 300; }
.text-bold-200 {	font-weight: 200; }
.text-bold-100 {	font-weight: 100; }
.text-xl {
	font-size: 60px;
	line-height: .75;
	margin-bottom: .5em;
}

.text-spacing {
	letter-spacing: 3.5px;
}

// Assist match height js
.match-height,
.m-mh {
    display: block;

	@media (max-width: $screen-tablet) {
		display: table;

	    > div,
	    > a,
	    > nav,
	    > *,
	    > button,
	    .align-element {
	        display: table-cell;
	        vertical-align: middle;
	    }
	}
}

// Assist match height js
.match-height-columns {
    display: block;

	@media (min-width: 321px) {
		display: inline-table;

	    > div,
	    > a,
	    > nav,
	    > *,
	    > button,
	    .align-element {
	        display: table-cell;
	        vertical-align: middle;
	    }
	}
}

// Align elements
.align-x {
	@include center(true, false);
}

.align-y {
	@include center(false, true);
}

.align-xy {
	@include center();
}

.align-bottom {
	position: absolute;
	bottom: 0;
}

//
// Generics grid list
//
.grid-list {
	clear: both;
	list-style-type: none;
	padding: 0;
	@include clearfix();

	.item-list {
		float: left;
		margin: 0 3.8% 1.992em 0;
		padding: 0;
		position: relative;
		width: 48%;
		margin-left: 0;

		@media (max-width: $screen-desktop) {
			&:nth-child(2n+1) {
	            clear: both;
	        }

	        &:nth-child(2n+2) {
	            margin-right: 0;
	        }
	    }
	}

	&.column-xs-1 {
		@media (max-width: $screen-phone) {
			.item-list {
				width: 100%;
				margin-right: 0;
			}
		}
	}

	@media (min-width: $screen-desktop) {
		&.columns-2 {
			.item-list {
				width: 48%;

				&:nth-child(2n+1) {
	                clear: both;
	            }

	            &:nth-child(2n+2) {
	                margin-right: 0;
	            }
			}
		}

		&.columns-3 {
            .item-list {
                width: 30.75%;

                &:nth-child(3n+1) {
                    clear: both;
                }

                &:nth-child(3n+3) {
                    margin-right: 0;
                }
            }
        }

        &.columns-4 {
            .item-list {
                width: 22.05%;

                &:nth-child(4n+1) {
                    clear: both;
                }

                &:nth-child(4n+4) {
                    margin-right: 0;
                }
            }
        }

        &.columns-5 {
            .item-list {
                width: 16.95%;

                &:nth-child(5n+1) {
                    clear: both;
                }

                &:nth-child(5n+5) {
                    margin-right: 0;
                }
            }
        }

        &.columns-6 {
            .item-list {
                width: 13.5%;

                &:nth-child(6n+1) {
                    clear: both;
                }

                &:nth-child(6n+6) {
                    margin-right: 0;
                }
            }
        }

        &.columns-7 {
            .item-list {
                width: 11.02%;

                &:nth-child(7n+1) {
                    clear: both;
                }

                &:nth-child(7n+7) {
                    margin-right: 0;
                }
            }
        }

        &.columns-8 {
            .item-list {
                width: 9.17%;

                &:nth-child(8n+1) {
                    clear: both;
                }

                &:nth-child(8n+8) {
                    margin-right: 0;
                }
            }
        }
    }
}